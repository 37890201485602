<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="card card-custom card-transparent">
          <div class="card-body p-0">
            <!--begin: Wizard-->
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div class="wizard-nav">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-number">1</div>
                      <div class="wizard-label">
                        <div class="wizard-title">
                          {{ $t('COMPANIES.COMPANY_INFO') }}
                        </div>
                        <div class="wizard-desc">
                          {{ $t('COMPANIES.COMPANY_INFO_DETAIL') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wizard-step" data-wizard-type="step">
                    <div class="wizard-wrapper">
                      <div class="wizard-number">2</div>
                      <div class="wizard-label">
                        <div class="wizard-title">
                          {{ $t('COMPANIES.MODULES_AND_LOGGERS') }}
                        </div>
                        <div class="wizard-desc">
                          {{ $t('COMPANIES.MODULES_AND_LOGGERS_DETAIL') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wizard-step" data-wizard-type="step">
                    <div class="wizard-wrapper">
                      <div class="wizard-number">3</div>
                      <div class="wizard-label">
                        <div class="wizard-title">
                          {{ $t('COMPANIES.USERS') }}
                        </div>
                        <div class="wizard-desc">
                          {{ $t('COMPANIES.USERS_DETAIL') }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="wizard-step" data-wizard-type="step">
                    <div class="wizard-wrapper">
                      <div class="wizard-number">4</div>
                      <div class="wizard-label">
                        <div class="wizard-title">
                          {{ $t('COMPANIES.FINAL_CHECK') }}
                        </div>
                        <div class="wizard-desc">
                          {{ $t('COMPANIES.FINAL_CHECK_DETAIL') }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Nav -->

              <!--begin: Wizard Body-->
              <div class="card card-custom card-shadowless rounded-top-0">
                <div class="card-body p-0">
                  <div
                    class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10"
                  >
                    <div class="col-xl-12 col-xxl-7">
                      <!--begin: Wizard Form-->
                      <form class="form mt-0 mt-lg-10" id="kt_form">
                        <!--begin: Wizard Step 1-->
                        <div
                          class="pb-5"
                          data-wizard-type="step-content"
                          data-wizard-state="current"
                        >
                          <div class="form-group">
                            <label class="font-weight-bold" for="companyName">
                              {{ $t('GENERAL.NAME') }}</label
                            >
                            <input
                              id="companyName"
                              v-model="company.name"
                              type="text"
                              class="form-control form-control-solid form-control-lg"
                              name="companyName"
                              :placeholder="$t('COMPANIES.FORMS.COMPANY_NAME')"
                            />
                          </div>
                          <div class="form-group">
                            <label
                              class="font-weight-bold"
                              for="companyDescription"
                              >{{ $t('GENERAL.DESCRIPTION') }}</label
                            >
                            <textarea
                              id="companyDescription"
                              v-model="company.description"
                              rows="5"
                              class="form-control form-control-solid form-control-lg"
                              name="companyDescription"
                              :placeholder="
                                $t('COMPANIES.FORMS.SHORT_DESCRIPTION')
                              "
                            ></textarea>
                          </div>
                          <div class="form-group">
                            <label class="font-weight-bold" for="companyName"
                              >OIB</label
                            >
                            <input
                              id="companyOib"
                              v-model="company.oib"
                              type="text"
                              class="form-control form-control-solid form-control-lg"
                              name="companyOib"
                              :placeholder="$t('COMPANIES.FORMS.OIB_NUMBER')"
                            />
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label
                                  class="font-weight-bold"
                                  for="companyinVatSystem"
                                  >{{ $t('COMPANIES.IN_VAT_SYSTEM') }}</label
                                >
                                <VtSwitch
                                  @checkedEvent="updateInVatSystem"
                                  :is-checked="company.inVatSystem"
                                  classes="switch switch-icon"
                                  name="companyInVatSystem"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label
                                  class="font-weight-bold"
                                  for="companyPdv"
                                  >{{ $t('GENERAL.VAT') }}</label
                                >
                                <input
                                  id="companyPdv"
                                  v-model.number="company.pdv"
                                  type="number"
                                  class="form-control form-control-solid form-control-lg"
                                  name="companyOdv"
                                  :placeholder="$t('GENERAL.VAT')"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label
                                  class="font-weight-bold"
                                  for="operationalCosts"
                                  >{{
                                    $t('COMPANIES.OPERATIONAL_COSTS')
                                  }}</label
                                >
                                <input
                                  id="operationalCosts"
                                  v-model.number="company.operationalCosts"
                                  type="number"
                                  class="form-control form-control-solid form-control-lg"
                                  name="operationalCosts"
                                  :placeholder="
                                    $t('COMPANIES.OPERATIONAL_COSTS')
                                  "
                                />
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label
                              class="font-weight-bold"
                              for="companyAddrees"
                              >{{ $t('GENERAL.ADDRESS') }}</label
                            >
                            <input
                              id="companyAddrees"
                              v-model="company.address"
                              type="text"
                              class="form-control form-control-solid form-control-lg"
                              name="companyAddrees"
                              :placeholder="
                                $t('COMPANIES.FORMS.COMPANY_ADDRESS')
                              "
                            />
                          </div>
                          <div class="row">
                            <div class="col-xl-6">
                              <div class="form-group">
                                <label
                                  class="font-weight-bold"
                                  for="companyPhone"
                                  >{{ $t('GENERAL.PHONE') }}</label
                                >
                                <input
                                  id="companyPhone"
                                  v-model="company.phone"
                                  type="tel"
                                  class="form-control form-control-solid form-control-lg"
                                  name="phone"
                                  :placeholder="
                                    $t('COMPANIES.FORMS.CONTACT_PHONE')
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-xl-6">
                              <div class="form-group">
                                <label
                                  class="font-weight-bold"
                                  for="companyEmail"
                                  >{{ $t('GENERAL.EMAIL') }}</label
                                >
                                <input
                                  id="companyEmail"
                                  v-model="company.email"
                                  type="email"
                                  class="form-control form-control-solid form-control-lg"
                                  name="companyEmail"
                                  :placeholder="$t('GENERAL.EMAIL')"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label
                                  class="font-weight-bold"
                                  for="companyinVatSystem"
                                  >{{
                                    $t('COMPANIES.SEND_EMAIL_NOTIFICATIONS')
                                  }}</label
                                >
                                <VtSwitch
                                  @checkedEvent="updateSendEmailNotifications"
                                  :is-checked="company.sendEmailNotifications"
                                  classes="switch switch-icon"
                                  name="companySendEmailNotifications"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="separator separator-dashed my-10"></div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder">{{
                                  $t('COMPANIES.NOTIFICATION_EMAILS')
                                }}</label>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder">{{
                                  $t('COMPANIES.DAILY_REPORTS')
                                }}</label>
                              </div>
                            </div>
                            <div
                              v-for="(dailyReport, index) in company
                                .notificationsEmails.dailyReports"
                              :key="'dailyReport' + index"
                              class="col-md-12 mb-5"
                            >
                              <div class="row">
                                <div class="col-md-11">
                                  <div class="input-group">
                                    <input
                                      type="email"
                                      v-model="
                                        company.notificationsEmails
                                          .dailyReports[index]
                                      "
                                      class="form-control form-control-solid"
                                      style="font-weight: bold"
                                      :placeholder="
                                        $t('COMPANIES.FORMS.EMAIL_ADDRESS')
                                      "
                                    />
                                  </div>
                                </div>
                                <div class="col-lg-1">
                                  <a
                                    @click="removeDailyReportEmail(index)"
                                    href="javascript:;"
                                    class="btn font-weight-bold btn-danger btn-icon"
                                  >
                                    <i class="la la-remove"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <button
                                @click.prevent="addDailyReportEmail"
                                class="btn btn-light-primary btn-block"
                              >
                                {{
                                  $t('GENERAL.ADD') + ' ' + $t('GENERAL.EMAIL')
                                }}
                              </button>
                              <div
                                class="separator separator-dashed my-10"
                              ></div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder">{{
                                  $t('GENERAL.CRITICAL_TOTAL')
                                }}</label>
                              </div>
                            </div>
                            <div
                              v-for="(criticalDevice, index) in company
                                .notificationsEmails.criticalDevices"
                              :key="'criticalDevice' + index"
                              class="col-md-12 mb-5"
                            >
                              <div class="row">
                                <div class="col-md-11">
                                  <div class="input-group">
                                    <input
                                      type="email"
                                      v-model="
                                        company.notificationsEmails
                                          .criticalDevices[index]
                                      "
                                      class="form-control form-control-solid"
                                      style="font-weight: bold"
                                      :placeholder="
                                        $t('COMPANIES.FORMS.EMAIL_ADDRESS')
                                      "
                                    />
                                  </div>
                                </div>
                                <div class="col-lg-1">
                                  <a
                                    @click="removeCriticalDeviceEmail(index)"
                                    href="javascript:;"
                                    class="btn font-weight-bold btn-danger btn-icon"
                                  >
                                    <i class="la la-remove"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <button
                                @click.prevent="addCriticalDeviceEmail"
                                class="btn btn-light-primary btn-block"
                              >
                                {{
                                  $t('GENERAL.ADD') + ' ' + $t('GENERAL.EMAIL')
                                }}
                              </button>
                              <div
                                class="separator separator-dashed my-10"
                              ></div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder">{{
                                  $t('GENERAL.PROBLEMATIC_TOTAL')
                                }}</label>
                              </div>
                            </div>
                            <div
                              v-for="(problematicDevice, index) in company
                                .notificationsEmails.problematicDevices"
                              :key="'problematicDevice' + index"
                              class="col-md-12 mb-5"
                            >
                              <div class="row">
                                <div class="col-md-11">
                                  <div class="input-group">
                                    <input
                                      type="email"
                                      v-model="
                                        company.notificationsEmails
                                          .problematicDevices[index]
                                      "
                                      class="form-control form-control-solid"
                                      style="font-weight: bold"
                                      :placeholder="
                                        $t('COMPANIES.FORMS.EMAIL_ADDRESS')
                                      "
                                    />
                                  </div>
                                </div>
                                <div class="col-lg-1">
                                  <a
                                    @click="removeProblematicDeviceEmail(index)"
                                    href="javascript:;"
                                    class="btn font-weight-bold btn-danger btn-icon"
                                  >
                                    <i class="la la-remove"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <button
                                @click.prevent="addProblematicDeviceEmail"
                                class="btn btn-light-primary btn-block"
                              >
                                {{
                                  $t('GENERAL.ADD') + ' ' + $t('GENERAL.EMAIL')
                                }}
                              </button>

                              <div
                                class="separator separator-dashed my-10"
                              ></div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder">{{
                                  $t('COMPANIES.DEPOSIT_NOTIFICATIONS')
                                }}</label>
                              </div>
                            </div>
                            <div
                              v-for="(depositNotifications, index) in company
                                .notificationsEmails.depositNotifications"
                              :key="'depositNotifications' + index"
                              class="col-md-12 mb-5"
                            >
                              <div class="row">
                                <div class="col-md-11">
                                  <div class="input-group">
                                    <input
                                      type="email"
                                      v-model="
                                        company.notificationsEmails
                                          .depositNotifications[index]
                                      "
                                      class="form-control form-control-solid"
                                      style="font-weight: bold"
                                      :placeholder="
                                        $t('COMPANIES.FORMS.EMAIL_ADDRESS')
                                      "
                                    />
                                  </div>
                                </div>
                                <div class="col-lg-1">
                                  <a
                                    @click="
                                      removeDepositNotificationEmail(index)
                                    "
                                    href="javascript:;"
                                    class="btn font-weight-bold btn-danger btn-icon"
                                  >
                                    <i class="la la-remove"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <button
                                @click.prevent="addDepositNotificationEmail"
                                class="btn btn-light-primary btn-block"
                              >
                                {{
                                  $t('GENERAL.ADD') + ' ' + $t('GENERAL.EMAIL')
                                }}
                              </button>
                              <div
                                class="separator separator-dashed my-10"
                              ></div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group mb-2">
                                <label class="font-weight-bolder">{{
                                  $t('COMPANIES.DEVICE_EMPTY')
                                }}</label>
                              </div>
                            </div>
                            <div
                              v-for="(
                                deviceEmptyEventNotifications, index
                              ) in company.notificationsEmails
                                .deviceEmptyEventNotifications"
                              :key="'deviceEmptyEventNotifications' + index"
                              class="col-md-12 mb-5"
                            >
                              <div class="row">
                                <div class="col-md-11">
                                  <div class="input-group">
                                    <input
                                      type="email"
                                      v-model="
                                        company.notificationsEmails
                                          .deviceEmptyEventNotifications[index]
                                      "
                                      class="form-control form-control-solid"
                                      style="font-weight: bold"
                                      :placeholder="
                                        $t('COMPANIES.FORMS.EMAIL_ADDRESS')
                                      "
                                    />
                                  </div>
                                </div>
                                <div class="col-lg-1">
                                  <a
                                    @click="
                                      removeDeviceEmptyEventNotificationEmail(
                                        index
                                      )
                                    "
                                    href="javascript:;"
                                    class="btn font-weight-bold btn-danger btn-icon"
                                  >
                                    <i class="la la-remove"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <button
                                @click.prevent="
                                  addDeviceEmptyEventNotificationEmail
                                "
                                class="btn btn-light-primary btn-block"
                              >
                                {{
                                  $t('GENERAL.ADD') + ' ' + $t('GENERAL.EMAIL')
                                }}
                              </button>
                            </div>
                          </div>
                        </div>
                        <!--end: Wizard Step 1-->

                        <!--begin: Wizard Step 2-->
                        <div class="pb-5" data-wizard-type="step-content">
                          <div class="form-group">
                            <label
                              class="font-weight-bold"
                              for="companyActiveModules"
                              >{{ $t('COMPANIES.MODULES') }}</label
                            >
                            <vSelect
                              multiple
                              class="form-control form-control--modules-select"
                              :placeholder="$t('COMPANIES.FORMS.SELECT_MODULE')"
                              v-model="company.activeModules"
                              :options="modules"
                            />
                          </div>

                          <div
                            v-if="
                              currentUserRole === 'ROLE_ROOT' ||
                              currentUserRole === 'ROLE_SUPER_ADMIN' ||
                              currentUserCompanyModule[0] === 'gaming'
                            "
                            class="form-group"
                          >
                            <label
                              class="font-weight-bold mb-3"
                              for="companyActiveModules"
                              >{{ $t('COMPANIES.MODULE_COMPONENTS') }}</label
                            >
                            <div class="row mb-5">
                              <div class="col-md-12">
                                <label class="font-weight-bold mb-3">{{
                                  $t('MAIN_MENU.DASHBOARD')
                                }}</label>
                                <div
                                  class="p-5"
                                  style="
                                    border: 1px solid #ebedf3;
                                    border-radius: 0.42rem;
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('COMPANIES.INDEX_CHARTS')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentIndexCharts
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .dashboardIndexCharts
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('GENERAL.PROBLEMATIC_TOTAL')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentProblematicDevices
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .dashboardProblematicDevices
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('COMPANIES.DEVICES_REVENUE')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentDevicesLast30DaysRevenue
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .dashboardDevicesLast30DaysRevenue
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-12">
                                <label class="font-weight-bold mb-3">{{
                                  $t('MAIN_MENU.COMPANY')
                                }}</label>
                                <div
                                  class="p-5"
                                  style="
                                    border: 1px solid #ebedf3;
                                    border-radius: 0.42rem;
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-12">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('MAIN_MENU.DAILY_REVENUE')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentDailyRevenue
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .companyDailyRevenue
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-12">
                                <label class="font-weight-bold mb-3">{{
                                  $t('MAIN_MENU.DEVICES')
                                }}</label>
                                <div
                                  class="p-5"
                                  style="
                                    border: 1px solid #ebedf3;
                                    border-radius: 0.42rem;
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('MAIN_MENU.MAINTENANCE')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentDevicesMaintenance
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .devicesMaintenance
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('MAIN_MENU.DEVICES_MAP')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentDevicesMap
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .devicesMap
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-12">
                                <label class="font-weight-bold mb-3">{{
                                  $t('MAIN_MENU.LESSORS')
                                }}</label>
                                <div
                                  class="p-5"
                                  style="
                                    border: 1px solid #ebedf3;
                                    border-radius: 0.42rem;
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('MAIN_MENU.LESSORS')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentLessors
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .lessors
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-12">
                                <label class="font-weight-bold mb-3">{{
                                  $t('MAIN_MENU.TODAY_REVENUE')
                                }}</label>
                                <div
                                  class="p-5"
                                  style="
                                    border: 1px solid #ebedf3;
                                    border-radius: 0.42rem;
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('MAIN_MENU.TODAY_REVENUE')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentTodayRevenue
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .todayRevenue
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-12">
                                <label class="font-weight-bold mb-3">{{
                                  $t('MAIN_MENU.FINANCE_REPORT')
                                }}</label>
                                <div
                                  class="p-5"
                                  style="
                                    border: 1px solid #ebedf3;
                                    border-radius: 0.42rem;
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('COMPANIES.REPORTS_IN_RANGE')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentFinanceReportReportByDaysInRange
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .financeReportReportByDaysInRange
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('GENERAL.WEEKS')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentFinanceReportWeeks
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .financeReportWeeks
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('GENERAL.MONTH')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentFinanceReportMonth
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .financeReportMonth
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('GENERAL.YEAR')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentFinanceReportYear
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .financeReportYear
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('COMPANIES.AR_DAYS_IN_WEEK')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentFinanceReportAverageReportByDaysInWeak
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .financeReportAverageReportByDaysInWeak
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('COMPANIES.HOURS_OF_THE_DAY')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentFinanceReportAverageReportByHoursOfTheDay
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .financeReportAverageReportByHoursOfTheDay
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-12">
                                <label class="font-weight-bold mb-3">{{
                                  $t('MAIN_MENU.COMPARATIVE_ANALYSIS')
                                }}</label>
                                <div
                                  class="p-5"
                                  style="
                                    border: 1px solid #ebedf3;
                                    border-radius: 0.42rem;
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('MAIN_MENU.COMPARATIVE_ANALYSIS')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentComparativeAnalysis
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .comparativeAnalysis
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-12">
                                <label class="font-weight-bold mb-3">{{
                                  $t('MAIN_MENU.TRANSACTIONS')
                                }}</label>
                                <div
                                  class="p-5"
                                  style="
                                    border: 1px solid #ebedf3;
                                    border-radius: 0.42rem;
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('MAIN_MENU.TRANSACTIONS')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentTransactions
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .transactions
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mb-5">
                              <div class="col-md-12">
                                <label class="font-weight-bold mb-3">{{
                                  $t('GENERAL.GENERATE_EXPORTS')
                                }}</label>
                                <div
                                  class="p-5"
                                  style="
                                    border: 1px solid #ebedf3;
                                    border-radius: 0.42rem;
                                  "
                                >
                                  <div class="row">
                                    <div class="col-md-4">
                                      <div class="form-group">
                                        <label class="font-weight-bold">{{
                                          $t('GENERAL.GENERATE_EXPORTS')
                                        }}</label>
                                        <VtSwitch
                                          @checkedEvent="
                                            updateModuleComponentGenerateExports
                                          "
                                          :is-checked="
                                            company.activeModuleComponents
                                              .generateExports
                                          "
                                          classes="switch switch-icon"
                                          name="moduleComponentTransactions"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <label
                              class="font-weight-bold"
                              for="companyActiveModules"
                              >{{ $t('MAIN_MENU.LOGGERS') }}</label
                            >
                            <vSelect
                              multiple
                              class="form-control form-control--logger-select"
                              placeholder="Select Loggers"
                              v-model="company.loogers"
                              :options="loogers"
                              :reduce="(looger) => looger['@id']"
                              label="imei"
                            />
                          </div>
                        </div>
                        <!--end: Wizard Step 2-->

                        <!--begin: Wizard Step 3-->
                        <div class="pb-5" data-wizard-type="step-content">
                          <label
                            class="font-weight-bold"
                            for="companyActiveModules"
                            >{{ $t('MAIN_MENU.USERS') }}</label
                          >
                          <vSelect
                            multiple
                            class="form-control form-control--users-select"
                            :placeholder="$t('COMPANIES.FORMS.SELECT_USERS')"
                            v-model="company.users"
                            :options="users"
                            :reduce="(user) => user['@id']"
                            label="email"
                          />
                        </div>
                        <!--end: Wizard Step 3-->

                        <!--begin: Wizard Step 4-->
                        <div class="pb-5" data-wizard-type="step-content">
                          <div class="mb-10 font-weight-bold text-dark">
                            {{ $t('COMPANIES.CHECK_INPUT') }}
                          </div>
                          <div class="border-bottom mb-5 pb-5">
                            <div class="font-weight-bold mb-3">
                              {{ $t('COMPANIES.COMPANY_INFO') }}:
                            </div>
                            <div class="line-height-md">
                              {{ company.name }}
                              <br />
                              {{ $t('GENERAL.DESCRIPTION') }}:
                              {{ company.description }} <br />
                              OIB: {{ company.oib }} <br />
                              {{ $t('GENERAL.ADDRESS') }}:
                              {{ company.address }} <br />
                              {{ $t('GENERAL.PHONE') }}: {{ company.phone }}
                              <br />
                              {{ $t('GENERAL.EMAIL') }}: {{ company.email }}
                            </div>
                          </div>
                          <div class="border-bottom mb-5 pb-5">
                            <div class="font-weight-bold mb-3">
                              {{ $t('COMPANIES.MODULES') }}:
                            </div>
                            <div class="line-height-md">
                              <span
                                v-for="modul in company.activeModules"
                                :key="modul"
                                >{{ modul }}</span
                              >
                            </div>
                          </div>
                          <div class="mb-5">
                            <div class="font-weight-bold mb-3">
                              {{ $t('MAIN_MENU.USERS') }}:
                            </div>
                            <div class="line-height-md">
                              <span v-for="user in company.users" :key="user">{{
                                user
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <!--end: Wizard Step 4-->

                        <!--begin: Wizard Actions -->
                        <div
                          class="d-flex justify-content-between border-top pt-10"
                        >
                          <div class="mr-2">
                            <button
                              class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                              data-wizard-type="action-prev"
                            >
                              {{ $t('COMPANIES.FORMS.PREVIOUS_STEP') }}
                            </button>
                          </div>
                          <div>
                            <button
                              v-on:click="submit"
                              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                              data-wizard-type="action-submit"
                            >
                              {{ $t('COMPANIES.FORMS.EDIT_COMPANY') }}
                            </button>
                            <button
                              class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                              data-wizard-type="action-next"
                            >
                              {{ $t('COMPANIES.FORMS.NEXT_STEP') }}
                            </button>
                          </div>
                        </div>
                        <!--end: Wizard Actions -->
                      </form>
                      <!--end: Wizard Form-->
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Bpdy-->
            </div>
            <!--end: Wizard-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import KTUtil from '@/assets/js/components/util'
import KTWizard from '@/assets/js/components/wizard'
import ApiService from '@/core/services/api.service'
import vSelect from 'vue-select'
import VtSwitch from '@/view/content/components/switch/VtSwitch'
import { mapGetters } from 'vuex'

export default {
  name: 'EditCompany',
  components: {
    vSelect,
    VtSwitch,
  },
  data() {
    return {
      company: {
        name: null,
        description: null,
        oib: null,
        inVatSystem: false,
        operationalCosts: null,
        email: null,
        address: null,
        phone: null,
        pdv: null,
        activeModules: [],
        activeModuleComponents: {
          dashboardIndexCharts: false,
          dashboardProblematicDevices: false,
          dashboardDevicesLast30DaysRevenue: false,
          dashboardYearlyRevenue: false,
          companyDailyRevenue: false,
          devicesMessages: false,
          devicesMaintenance: false,
          devicesMap: false,
          lessors: false,
          detailedReviewAdvanceTable: false,
          todayRevenue: false,
          financeReportReportByDaysInRange: false,
          financeReportWeeks: false,
          financeReportMonth: false,
          financeReportYear: false,
          financeReportAverageReportByDaysInWeak: false,
          financeReportAverageReportByHoursOfTheDay: false,
          comparativeAnalysis: false,
          transactions: false,
          generateExports: false,
        },
        isActive: true,
        devices: [],
        users: [],
        loogers: [],
        sendEmailNotifications: false,
        notificationsEmails: {
          dailyReports: [],
          criticalDevices: [],
          problematicDevices: [],
          depositNotifications: [],
          deviceEmptyEventNotifications: [],
        },
      },
      modules: ['gaming', 'vending', 'parking'],
      devices: [],
      users: [],
      loogers: [],
    }
  },
  computed: {
    ...mapGetters(['currentUserRole', 'currentUserCompanyModule']),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Companies', route: '/companies/list' },
      { title: 'Edit' },
    ])

    const wizard = new KTWizard('kt_wizard_v4', {
      startStep: 1,
      clickableSteps: true,
    })

    wizard.on('beforeNext', function () {})

    wizard.on('change', function () {
      setTimeout(() => {
        KTUtil.scrollTop()
      }, 500)
    })

    ApiService.get('companies', this.$route.params.id).then(({ data }) => {
      this.company.name = data.name
      this.company.description = data.description
      this.company.oib = data.oib
      this.company.inVatSystem = data.inVatSystem
      this.company.email = data.email
      this.company.address = data.address
      this.company.operationalCosts = data.operationalCosts
      this.company.phone = data.phone
      this.company.pdv = parseFloat(data.pdv)
      this.company.activeModules = data.activeModules
      this.company.activeModuleComponents.dashboardIndexCharts =
        data.activeModuleComponents.dashboardIndexCharts
      this.company.activeModuleComponents.dashboardProblematicDevices =
        data.activeModuleComponents.dashboardProblematicDevices
      this.company.activeModuleComponents.dashboardDevicesLast30DaysRevenue =
        data.activeModuleComponents.dashboardDevicesLast30DaysRevenue
      this.company.activeModuleComponents.dashboardYearlyRevenue =
        data.activeModuleComponents.dashboardYearlyRevenue
      this.company.activeModuleComponents.companyDailyRevenue =
        data.activeModuleComponents.companyDailyRevenue
      this.company.activeModuleComponents.devicesMessages =
        data.activeModuleComponents.devicesMessages
      this.company.activeModuleComponents.devicesMaintenance =
        data.activeModuleComponents.devicesMaintenance
      this.company.activeModuleComponents.devicesMap =
        data.activeModuleComponents.devicesMap
      this.company.activeModuleComponents.lessors =
        data.activeModuleComponents.lessors
      this.company.activeModuleComponents.detailedReviewAdvanceTable =
        data.activeModuleComponents.detailedReviewAdvanceTable
      this.company.activeModuleComponents.todayRevenue =
        data.activeModuleComponents.todayRevenue
      this.company.activeModuleComponents.financeReportReportByDaysInRange =
        data.activeModuleComponents.financeReportReportByDaysInRange
      this.company.activeModuleComponents.financeReportWeeks =
        data.activeModuleComponents.financeReportWeeks
      this.company.activeModuleComponents.financeReportMonth =
        data.activeModuleComponents.financeReportMonth
      this.company.activeModuleComponents.financeReportYear =
        data.activeModuleComponents.financeReportYear
      this.company.activeModuleComponents.financeReportAverageReportByDaysInWeak =
        data.activeModuleComponents.financeReportAverageReportByDaysInWeak
      this.company.activeModuleComponents.financeReportAverageReportByHoursOfTheDay =
        data.activeModuleComponents.financeReportAverageReportByHoursOfTheDay
      this.company.activeModuleComponents.comparativeAnalysis =
        data.activeModuleComponents.comparativeAnalysis
      this.company.activeModuleComponents.transactions =
        data.activeModuleComponents.transactions
      this.company.activeModuleComponents.generateExports =
        data.activeModuleComponents.generateExports
      this.company.isActive = data.isActive
      this.company.devices = data.devices
      this.company.users = data.users
      this.company.loogers = data.loogers
      this.company.sendEmailNotifications = data.sendEmailNotifications

      this.company.notificationsEmails.dailyReports = [
        ...data.notificationsEmails.dailyReports,
      ]
      this.company.notificationsEmails.criticalDevices = [
        ...data.notificationsEmails.criticalDevices,
      ]
      this.company.notificationsEmails.problematicDevices = [
        ...data.notificationsEmails.problematicDevices,
      ]
      this.company.notificationsEmails.depositNotifications = [
        ...data.notificationsEmails.depositNotifications,
      ]
      this.company.notificationsEmails.deviceEmptyEventNotifications = [
        ...data.notificationsEmails.deviceEmptyEventNotifications,
      ]

      this.isLoaded = true
    })

    ApiService.get('devices').then((response) => {
      this.devices = [...response.data['hydra:member']]
      this.isLoaded = true
    })
    // ApiService.get('devices', `?company=${this.$route.params.id}`).then(
    //   (response) => {
    //     this.company.devices = [...response.data['hydra:member']]
    //     this.isLoaded = true
    //   }
    // )

    ApiService.get('users', '?isLoggerUser=false').then((response) => {
      this.users = [...response.data['hydra:member']]
      this.isLoaded = true
    })

    // ApiService.get('users', `?company=${this.$route.params.id}`).then(
    //   (response) => {
    //     this.company.users = [...response.data['hydra:member']]
    //     this.isLoaded = true
    //   }
    // )

    ApiService.get('loggers').then((response) => {
      this.loogers = [...response.data['hydra:member']]
      this.isLoaded = true
    })

    ApiService.get('loggers', `?company=${this.$route.params.id}`).then(
      (response) => {
        this.company.loogers = [...response.data['hydra:member']]
        this.isLoaded = true
      }
    )
  },
  methods: {
    updateInVatSystem() {
      this.company.inVatSystem = !this.company.inVatSystem
    },
    updateSendEmailNotifications() {
      this.company.sendEmailNotifications = !this.company.sendEmailNotifications
    },
    addDailyReportEmail() {
      this.company.notificationsEmails.dailyReports.push('')
    },
    addProblematicDeviceEmail() {
      this.company.notificationsEmails.problematicDevices.push('')
    },
    addCriticalDeviceEmail() {
      this.company.notificationsEmails.criticalDevices.push('')
    },
    addDepositNotificationEmail() {
      this.company.notificationsEmails.depositNotifications.push('')
    },
    addDeviceEmptyEventNotificationEmail() {
      this.company.notificationsEmails.deviceEmptyEventNotifications.push('')
    },
    removeDailyReportEmail(index) {
      const confirmation = confirm('Do you want to delete this email?')
      if (confirmation === false) return

      this.company.notificationsEmails.dailyReports.splice(index, 1)
    },
    removeProblematicDeviceEmail(index) {
      const confirmation = confirm('Do you want to delete this email?')
      if (confirmation === false) return

      this.company.notificationsEmails.problematicDevices.splice(index, 1)
    },
    removeCriticalDeviceEmail(index) {
      const confirmation = confirm('Do you want to delete this email?')
      if (confirmation === false) return

      this.company.notificationsEmails.criticalDevices.splice(index, 1)
    },
    removeDepositNotificationEmail(index) {
      const confirmation = confirm('Do you want to delete this email?')
      if (confirmation === false) return

      this.company.notificationsEmails.depositNotifications.splice(index, 1)
    },
    removeDeviceEmptyEventNotificationEmail(index) {
      const confirmation = confirm('Do you want to delete this email?')
      if (confirmation === false) return

      this.company.notificationsEmails.deviceEmptyEventNotifications.splice(
        index,
        1
      )
    },
    submit: function (e) {
      e.preventDefault()
      console.log(this.company)
      this.company.loogers = this.company.loogers.filter((el) => {
        return el != null
      })

      ApiService.update('companies', this.$route.params.id, this.company)
        .then(() => this.$router.push({ name: 'companies-list' }))
        .catch(() => {})
    },

    updateModuleComponentIndexCharts() {
      this.company.activeModuleComponents.indexCharts =
        !this.company.activeModuleComponents.indexCharts
    },
    updateModuleComponentProblematicDevices() {
      this.company.activeModuleComponents.dashboardProblematicDevices =
        !this.company.activeModuleComponents.dashboardProblematicDevices
    },
    updateModuleComponentDevicesLast30DaysRevenue() {
      this.company.activeModuleComponents.dashboardDevicesLast30DaysRevenue =
        !this.company.activeModuleComponents.dashboardDevicesLast30DaysRevenue
    },
    updateModuleComponentDailyRevenue() {
      this.company.activeModuleComponents.companyDailyRevenue =
        !this.company.activeModuleComponents.companyDailyRevenue
    },
    updateModuleComponentDevicesMaintenance() {
      this.company.activeModuleComponents.devicesMaintenance =
        !this.company.activeModuleComponents.devicesMaintenance
    },
    updateModuleComponentDevicesMap() {
      this.company.activeModuleComponents.devicesMap =
        !this.company.activeModuleComponents.devicesMap
    },
    updateModuleComponentLessors() {
      this.company.activeModuleComponents.lessors =
        !this.company.activeModuleComponents.lessors
    },
    updateModuleComponentTodayRevenue() {
      this.company.activeModuleComponents.todayRevenue =
        !this.company.activeModuleComponents.todayRevenue
    },
    updateModuleComponentFinanceReportReportByDaysInRange() {
      this.company.activeModuleComponents.financeReportReportByDaysInRange =
        !this.company.activeModuleComponents.financeReportReportByDaysInRange
    },
    updateModuleComponentFinanceReportWeeks() {
      this.company.activeModuleComponents.financeReportWeeks =
        !this.company.activeModuleComponents.financeReportWeeks
    },
    updateModuleComponentFinanceReportMonth() {
      this.company.activeModuleComponents.financeReportMonth =
        !this.company.activeModuleComponents.financeReportMonth
    },
    updateModuleComponentFinanceReportYear() {
      this.company.activeModuleComponents.financeReportYear =
        !this.company.activeModuleComponents.financeReportYear
    },
    updateModuleComponentFinanceReportAverageReportByDaysInWeak() {
      this.company.activeModuleComponents.financeReportAverageReportByDaysInWeak =
        !this.company.activeModuleComponents
          .financeReportAverageReportByDaysInWeak
    },
    updateModuleComponentFinanceReportAverageReportByHoursOfTheDay() {
      this.company.activeModuleComponents.financeReportAverageReportByHoursOfTheDay =
        !this.company.activeModuleComponents
          .financeReportAverageReportByHoursOfTheDay
    },
    updateModuleComponentComparativeAnalysis() {
      this.company.activeModuleComponents.comparativeAnalysis =
        !this.company.activeModuleComponents.comparativeAnalysis
    },
    updateModuleComponentTransactions() {
      this.company.activeModuleComponents.transactions =
        !this.company.activeModuleComponents.transactions
    },
    updateModuleComponentGenerateExports() {
      this.company.activeModuleComponents.generateExports =
        !this.company.activeModuleComponents.generateExports
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--logger-select,
  &--modules-select,
  &--users-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }

    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__clear {
      display: none;
    }

    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }

    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
